import http from "../https";

type getRefferalSystemStatisticsParamsType = {
    startDate?: Date;
    finishDate?: Date | null;
    isAllTime?: boolean;
};

export const getRefferalSystemStatisticsAsync = (params: getRefferalSystemStatisticsParamsType) =>
    http.get(
        `/api/referrals/statistics`,
        {},
        {
            params,
        }
    );
