import { Outlet } from "react-router-dom";
import { useGetRoles } from "./utils";

import NoAccessPage from "./pages/ErrorPages/NoAccess";

export const AdminPrivateRoute = () => {
  const { isAdmin } = useGetRoles();

  if (!isAdmin) {
    return <NoAccessPage />;
  }

  return <Outlet />;
};
