import { store } from "src/store";
import { phrases } from "src/constants";
import { TCourseInfo } from "src/typings";
import { addErrorMessage } from "src/slices";
import { getLessonInfoAsync } from "src/api";

const { dispatch } = store;

export const lessonLoader = async (lessonId?: string): Promise<TCourseInfo | void> => {
  try {
    if (lessonId) {
      const response = await getLessonInfoAsync(lessonId);
      if (response.status === 200 || response.status === 201) {
        return response.data as TCourseInfo;
      } else throw new Error();
    } else throw new Error();
  } catch (err: any) {
    dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
  }
};
