export const COURSE_FILE_FORMATS = [
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".bmp",
  ".svg",
  ".ppt",
  ".pptx",
  ".key",
  ".odp",
  ".doc",
  ".docx",
  ".pdf",
  ".txt",
  ".rtf",
  ".xls",
  ".xlsx",
  ".csv",
  ".mp3",
  ".wav",
  ".ogg",
];
