export const ApplicationsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#1D1B20"
        d="M11 14V9.85l-1.6 1.6L8 10l4-4 4 4-1.4 1.45-1.6-1.6V14h-2zm-6 7a1.99 1.99 0 01-1.425-.575A1.99 1.99 0 013 19V5c0-.55.192-1.017.575-1.4.4-.4.875-.6 1.425-.6h14c.55 0 1.017.2 1.4.6.4.383.6.85.6 1.4v14c0 .55-.2 1.025-.6 1.425-.383.383-.85.575-1.4.575H5zm0-2h14v-3h-3c-.5.633-1.1 1.125-1.8 1.475A4.757 4.757 0 0112 18a4.91 4.91 0 01-2.225-.525A5.36 5.36 0 018 16H5v3zm7-3c.633 0 1.208-.183 1.725-.55.517-.367.875-.85 1.075-1.45H19V5H5v9h4.2c.2.6.558 1.083 1.075 1.45.517.367 1.092.55 1.725.55zm-7 3h14H5z"
      ></path>
    </svg>
  );
};
