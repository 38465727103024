export const ADMIN_ROLES = [
    {
        label: "Администратор",
        value: "admin",
    },
    {
        label: "Куратор",
        value: "curator",
    },
    {
        label: "Спикер",
        value: "speaker",
    },
];

export const ADMIN_ROLES_VALUES = {
    admin: "admin",
    curator: "curator",
    speaker: "speaker",
};

export const ADMIN_ROLES_LABELS = {
    admin: "Администратор",
    curator: "Куратор",
    speaker: "Спикер",
};
