import React from "react";
import { classNames } from "primereact/utils";
import { Link } from "react-router-dom";

const MenuItem = ({ item, itemClick }) => {
  const className = classNames(item.className, { "p-disabled": item.disabled });
  const iconClassName = classNames("p-menuitem-icon", item.icon);

  return (
    <li className={className} style={item.style}>
      <Link to={item.to} className="p-menuitem-link">
        {item.icon && <span className={iconClassName}></span>}
        {item.label && <span className="p-menuitem-text">{item.label}</span>}
      </Link>
    </li>
  );
};

const MenuItems = ({ model, itemClick }) => {
  if (model) {
    const items = model.map((item, index) => {
      return (
        <React.Fragment key={item.label + "_" + index}>
          <MenuItem item={item} itemClick={itemClick} />
          {index === model.length - 1 ? null : <Separator />}
        </React.Fragment>
      );
    });

    return items;
  }

  return null;
};

const Separator = () => {
  return <li className="p-breadcrumb-chevron pi pi-chevron-right"></li>;
};

export const BreadCrumb = (props) => {
  const itemClick = (event, item) => {
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    if (!item.url) {
      event.preventDefault();
    }

    if (item.command) {
      item.command({
        originalEvent: event,
        item: item,
      });
    }
  };

  return (
    <nav
      id={props.id}
      className={classNames("p-breadcrumb p-component", props.className)}
      style={props.style}
      aria-label="Breadcrumb"
    >
      <ul>
        <MenuItems model={props.model} itemClick={itemClick} />
      </ul>
    </nav>
  );
};
