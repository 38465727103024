import { store } from "./store";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";

import ReactDOM from "react-dom";
import routes from "./router";

import "./assets/styles/variables.css";

ReactDOM.render(
  <Provider store={store}>
    <RouterProvider router={routes} />
  </Provider>,
  document.getElementById("root")
);
