export const DEFAULT_DIRECTIONS_LIST = [
    {
        title: "",
        text: "",
    },
    {
        title: "",
        text: "",
    },
];

export const DEFAULT_DIRECTIONS_FILES = [
    {
        mimetype: "",
        size: 0,
        name: "Примерный файл.jpg",
        url: "",
        key: "",
    },
    {
        mimetype: "",
        size: 0,
        name: "Примерный файл.jpg",
        url: "",
        key: "",
    },
];
