export const myProfileMenu = [
  {
    label: "Мои курсы",
    to: "courses",
  },
  { label: "Вопросы", to: "questions" },
  { label: "Лента", to: "feed" },
  { label: "Услуги", to: "services" },
  { label: "Трансляции", to: "broadcasts" },
];

export const curatorProfileMenu = [
  {
    label: "Мои курсы",
    to: "courses",
  },
  { label: "Вопросы", to: "questions" },
  { label: "Услуги", to: "services" },
  { label: "Трансляции", to: "broadcasts" },
];

export const profileMenu = [
  {
    label: "Курсы",
    to: "courses",
  },
  { label: "Вопросы", to: "questions" },
  { label: "Лента", to: "feed" },
  { label: "Услуги", to: "services" },
  { label: "Трансляции", to: "broadcasts" },
];
