export const COURSE_STATUSES = {
  draft: 0,
  forVerification: 1,
  returnRevision: 2,
  publish: 3,
  blocked: 4,
} as const;

export const COURSE_STATUSES_LABELS = {
  [COURSE_STATUSES.draft]: "Черновик",
  [COURSE_STATUSES.forVerification]: "В ожидании",
  [COURSE_STATUSES.returnRevision]: "Нужны правки",
  [COURSE_STATUSES.publish]: "Опубликовано",
  [COURSE_STATUSES.blocked]: "Заблокирован",
} as const;

export const DEFAULT_DIRECTION = {
  header: "",
  color: "#414196",
  description: "",
};

export const COURSE_MENU_LIST = {
  promo: "promo",
  content: "content",
  reviews: "reviews",
};

export const DEFAULT_POPULAR_QUESTION = {
  question: "",
  text: "",
};

export const DEFAULT_LINK = {
  link: "",
  name: "",
};

export const DEFAULT_FILE = {
  file: null,
  name: "",
};

export const DEFAULT_COURSE_REVIEW = {
  name: "",
  age: "",
  date: "",
  text: "",
  imageFile: null,
  file: null,
  files: [],
};

export const SETTINGS_DEFAULT_POPUPLAR_QUESTION = {
  category: "",
  question: "",
  response: "",
};

export const COURSE_LEVEL_LIST = [
  {
    label: "Beginner",
    value: "beginner",
  },
  {
    label: "Advanced",
    value: "advanced",
  },
];

export const ADMIN_COURSE_STATUSES_LIST = [
  {
    value: COURSE_STATUSES.draft,
    label: "Черновик",
  },
  {
    value: COURSE_STATUSES.forVerification,
    label: "В ожидании",
  },
  {
    value: COURSE_STATUSES.returnRevision,
    label: "Нужны правки",
  },
  {
    value: COURSE_STATUSES.publish,
    label: "Опубликовано",
  },
  {
    value: COURSE_STATUSES.blocked,
    label: "Заблокирован",
  },
];

export const SPEAKER_COURSE_STATUSES_LIST = [
  {
    value: COURSE_STATUSES.draft,
    label: "Черновик",
    disabled: true,
  },
  {
    value: COURSE_STATUSES.forVerification,
    label: "В ожидании",
    disabled: false,
  },
  {
    value: COURSE_STATUSES.returnRevision,
    label: "Нужны правки",
    disabled: true,
  },
  {
    value: COURSE_STATUSES.publish,
    label: "Опубликовано",
    disabled: true,
  },
  {
    value: COURSE_STATUSES.blocked,
    label: "Заблокирован",
    disabled: true,
  },
];

export const CHAGE_POSITION = {
  UP: "up",
  DOWN: "down",
};

export const LESSON_COUNTER_LABELS = ["урок", "урока", "уроков"];
