export const ExitIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0312 6.5L5.03125 12.5M5.03125 12.5L11.0312 18.5M5.03125 12.5H19.0312"
        stroke="#e01e5a"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
