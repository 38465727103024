import { Tag } from "../tags";
import { useMemo } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SubmitHandler, useForm } from "react-hook-form";
import { TFaqElement, TFaqElementUpdate, TTag } from "src/typings";

import styled from "./FaqElement.module.scss";

type FaqElementProps = {
  faqElement: TFaqElement;
  deleteFaqElement: (faqElementId: number) => void;
  editFaqElement: (id, data: TFaqElementUpdate) => void;
  index: number;
  tags: TTag[];
  addTag: (elementId: number, tagId: number) => void;
};

type Inputs = {
  question: string;
  answer: string;
};

export const FaqElement = ({
  faqElement,
  editFaqElement,
  addTag,
  deleteFaqElement,
  index,
  tags,
}: FaqElementProps) => {
  const { register, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      answer: faqElement.answer,
      question: faqElement.question,
    },
    mode: "onBlur",
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (faqElement.answer === data.answer && faqElement.question === data.question) return;
    editFaqElement(faqElement.id, { ...data, tagIds: [] });
  };

  const tagOptions = useMemo(
    () =>
      tags.map((el) => ({
        label: el.header,
        value: el.id,
      })),
    [tags]
  );

  return (
    <form onBlur={handleSubmit(onSubmit)} onSubmit={(event) => event.preventDefault()}>
      <div className={styled["accordion-list-item-header"]}>
        <p className={styled["accordion-list-item-header-text"]}>Вопрос {index + 1}</p>
        <Dropdown
          value={faqElement.tags[0]?.id}
          options={tagOptions}
          placeholder="Добавить категорию"
          className={styled["accordion-list-item-header-dropdown"]}
          onChange={({ value }) => {
            addTag(faqElement.id, value);
          }}
        />
        <div className={styled["accordion-list-item-header-tags"]}>
          {faqElement.tags.map((tag) => (
            <Tag key={tag.id} name={tag.header.toLocaleUpperCase()} color={tag.color} />
          ))}
        </div>
      </div>

      <div className={styled["accordion-list-item-mul"]}>
        <div className={styled["input-group"]}>
          <InputText
            maxLength={700}
            {...register("question")}
            placeholder="Текст вопроса"
            className={styled["accordion-list-item-input"]}
          />
          <InputText
            maxLength={1300}
            {...register("answer")}
            placeholder="Текст ответа"
            className={styled["accordion-list-item-input"]}
          />
        </div>
        <button
          type="button"
          className={styled["accordion-list-item-button"]}
          onClick={() => {
            deleteFaqElement(faqElement.id);
          }}
        >
          <i className={"pi pi-trash"} />
        </button>
      </div>
    </form>
  );
};
