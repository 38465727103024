export const RefferalIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.0312 15.5V19.5M19.0312 17.5H15.0312M16.0312 10.5H18.0312C19.1358 10.5 20.0312 9.60457 20.0312 8.5V6.5C20.0312 5.39543 19.1358 4.5 18.0313 4.5H16.0313C14.9267 4.5 14.0312 5.39543 14.0312 6.5V8.5C14.0312 9.60457 14.9267 10.5 16.0312 10.5ZM6.03125 20.5H8.03125C9.13582 20.5 10.0312 19.6046 10.0312 18.5V16.5C10.0312 15.3954 9.13582 14.5 8.03125 14.5H6.03125C4.92668 14.5 4.03125 15.3954 4.03125 16.5V18.5C4.03125 19.6046 4.92668 20.5 6.03125 20.5ZM6.03125 10.5H8.03125C9.13582 10.5 10.0312 9.60457 10.0312 8.5V6.5C10.0312 5.39543 9.13582 4.5 8.03125 4.5H6.03125C4.92668 4.5 4.03125 5.39543 4.03125 6.5V8.5C4.03125 9.60457 4.92668 10.5 6.03125 10.5Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
