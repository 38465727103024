import http from "../https";

type getAllUsersParamsType = {
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
};

export const getUsersAsync = (params: getAllUsersParamsType) =>
  http.get(
    `/api/students`,
    {},
    {
      params,
    }
  );
