import http from "../https";

import { downloadCSV } from "src/shared/helpers";

type getPaymentsCSVParams = {
  startAt?: Date;
  finishAt?: Date;
  isAllTime?: boolean;
};

export const getPaymentStatisticsCSV = async (params: getPaymentsCSVParams) => {
  const response = await http.get(
    "/api/payments/statistics/csv",
    {
      responseType: "text/csv",
    },
    { params }
  );

  return downloadCSV(response.data, "payment-statistics.csv");
};
