import { TDatesPairType } from "src/typings";
import { isSameDay, parse, startOfToday, endOfToday } from "date-fns";

export const getUniqDates = (dates: TDatesPairType): Date[] => {
  const isDate = (date: Date | null): date is Date => {
    return !!date;
  };

  const filteredDates = dates ? dates.filter(isDate) : [];
  return filteredDates.length < 2
    ? filteredDates
    : isSameDay(filteredDates[0], filteredDates[1])
      ? [filteredDates[0]]
      : filteredDates;
};

export const getSelectedDate = (dates: TDatesPairType) =>
  dates
    ? getUniqDates(dates)
      ?.map((date) =>
        date.toLocaleDateString("ru", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
      )
      ?.join(" - ")
    : "";

export const getDateFromStringWithReference = (
  dateString: string,
  referenceDate: Date
): Date | null => {
  if (dateString) {
    try {
      const date = parse(dateString, "dd.MM.yyyy", referenceDate);
      return date;
    } catch (err) {
      console.error(err);
      return null;
    }
  } else {
    return null;
  }
};

export const getDatesFromString = (datesString: string): TDatesPairType => {
  const dateStringArray = datesString.split(" - ");

  const newDates = [
    getDateFromStringWithReference(dateStringArray[0], startOfToday()),
    getDateFromStringWithReference(dateStringArray[1], endOfToday()),
  ];
  return newDates[0] ? [newDates[0], newDates[1]] : undefined;
};

export const getDateMetaObject = (inputDate: Date) => {
  const date = new Date(inputDate);
  return {
    day: date?.getDate(),
    month: date?.getMonth(),
    year: date?.getFullYear(),
  };
};

export const checkIfDateButtonActive = (buttonDates: TDatesPairType, dates: TDatesPairType) => {
  return (
    (!buttonDates && !dates) ||
    (buttonDates &&
      buttonDates[0] &&
      buttonDates[1] && [isSameDay(buttonDates[0], buttonDates[1])] &&
      dates &&
      !dates[1] &&
      dates[0] &&
      isSameDay(buttonDates[0], dates[0])) ||
    (buttonDates &&
      dates &&
      buttonDates[0] &&
      buttonDates[1] &&
      dates[0] &&
      dates[1] &&
      isSameDay(buttonDates[0], dates[0]) &&
      isSameDay(buttonDates[1], dates[1]))
  );
};

export const getTimeZoneInHours: () => number = () => -(new Date().getTimezoneOffset() / 60);
