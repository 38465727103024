export const AdminsIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7826 12.3479C9.75071 12.3479 8.74198 12.0419 7.88398 11.4686C7.02599 10.8953 6.35726 10.0804 5.96237 9.12709C5.56748 8.17373 5.46415 7.12469 5.66547 6.11262C5.86678 5.10054 6.36369 4.17089 7.09336 3.44123C7.82302 2.71156 8.75267 2.21465 9.76475 2.01334C10.7768 1.81202 11.8259 1.91535 12.7792 2.31024C13.7326 2.70513 14.5474 3.37386 15.1207 4.23185C15.694 5.08985 16 6.09858 16 7.13048C16 8.51422 15.4503 9.84128 14.4719 10.8197C13.4934 11.7982 12.1663 12.3479 10.7826 12.3479ZM10.7826 4.00004C10.1635 4.00004 9.55823 4.18364 9.04343 4.52762C8.52864 4.87159 8.1274 5.3605 7.89047 5.93251C7.65353 6.50453 7.59154 7.13395 7.71233 7.7412C7.83311 8.34844 8.13126 8.90623 8.56906 9.34403C9.00686 9.78183 9.56465 10.08 10.1719 10.2008C10.7791 10.3216 11.4086 10.2596 11.9806 10.0226C12.5526 9.78569 13.0415 9.38445 13.3855 8.86966C13.7294 8.35486 13.913 7.74962 13.913 7.13048C13.913 6.30024 13.5832 5.504 12.9962 4.91693C12.4091 4.32986 11.6129 4.00004 10.7826 4.00004Z"
        fill="#293241"
      />
      <path
        d="M20.5217 22.0872C20.2461 22.0836 19.9828 21.9725 19.7879 21.7776C19.593 21.5827 19.4819 21.3194 19.4783 21.0438C19.4783 18.3307 18.0035 16.522 10.7826 16.522C3.56174 16.522 2.08696 18.3307 2.08696 21.0438C2.08696 21.3205 1.97702 21.5859 1.78133 21.7816C1.58564 21.9773 1.32023 22.0872 1.04348 22.0872C0.766731 22.0872 0.501318 21.9773 0.305628 21.7816C0.109937 21.5859 0 21.3205 0 21.0438C0 14.4351 7.55478 14.4351 10.7826 14.4351C14.0104 14.4351 21.5652 14.4351 21.5652 21.0438C21.5616 21.3194 21.4505 21.5827 21.2556 21.7776C21.0607 21.9725 20.7974 22.0836 20.5217 22.0872Z"
        fill="#293241"
      />
      <path
        d="M20.5217 14.7827C20.2461 14.7791 19.9828 14.668 19.7879 14.4731C19.593 14.2782 19.4819 14.0148 19.4783 13.7392V8.86965C19.4783 8.5929 19.5882 8.32749 19.7839 8.1318C19.9796 7.93611 20.245 7.82617 20.5217 7.82617C20.7985 7.82617 21.0639 7.93611 21.2596 8.1318C21.4553 8.32749 21.5652 8.5929 21.5652 8.86965V13.7392C21.5616 14.0148 21.4505 14.2782 21.2556 14.4731C21.0607 14.668 20.7974 14.7791 20.5217 14.7827Z"
        fill="#293241"
      />
      <path
        d="M22.9565 12.3477H18.087C17.8102 12.3477 17.5448 12.2378 17.3491 12.0421C17.1534 11.8464 17.0435 11.581 17.0435 11.3042C17.0435 11.0275 17.1534 10.7621 17.3491 10.5664C17.5448 10.3707 17.8102 10.2607 18.087 10.2607H22.9565C23.2333 10.2607 23.4987 10.3707 23.6944 10.5664C23.8901 10.7621 24 11.0275 24 11.3042C24 11.581 23.8901 11.8464 23.6944 12.0421C23.4987 12.2378 23.2333 12.3477 22.9565 12.3477Z"
        fill="#293241"
      />
    </svg>
  );
};
