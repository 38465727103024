import http from "../https";

type requestData = {
  firstName: string;
  lastName: string;
  website: string;
  telegram: string;
  instagram: string;
  youtube: string;
  biography: string;
};

export const editMyInfoAsync = (myData: requestData) => http.patch(`/api/admins/my-info`, myData);
