import http from "../https";

import { downloadCSV } from "src/shared/helpers";

type getPaymentsCSVParams = {
  status?: string;
};

export const getPaymentsCSV = async (params: getPaymentsCSVParams) => {
  const response = await http.get(
    "/api/payments/csv",
    {
      responseType: "text/csv",
    },
    { params }
  );

  return downloadCSV(response.data, "payments.csv");
};
