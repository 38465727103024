export const WalletIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.0312 8.5V8.5C20.1358 8.5 21.0312 9.39543 21.0312 10.5V18.5C21.0312 19.6046 20.1358 20.5 19.0312 20.5H6.03125C4.3744 20.5 3.03125 19.1569 3.03125 17.5V7.5C3.03125 5.84315 4.3744 4.5 6.03125 4.5H17.0312C18.1358 4.5 19.0312 5.39543 19.0312 6.5V8.5ZM19.0312 8.5H7.03125M17.0312 14.5H16.0312"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
