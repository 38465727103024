import { FC } from "react";

import styled from "./EmptyImage.module.scss";

type EmptyImageProps = {};

export const EmptyImage: FC<EmptyImageProps> = () => {
    return (
        <div className={styled["container"]}>
            <i className="pi pi-image" />
            <p>Обложка не загружена</p>
        </div>
    );
};
