import AppWrapper from "./AppWrapper";

import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const token = localStorage.getItem("token");

  if (!token) return <Navigate to={"/login"} />;

  return (
    <AppWrapper>
      <Outlet />
    </AppWrapper>
  );
};

export default PrivateRoute;
