import { store } from "src/store";
import { phrases } from "src/constants";
import { addErrorMessage } from "src/slices";
import { getAdminAsync, getMyInfoAsync } from "src/api";

const { dispatch } = store;

export const profileLoader = async (id?: string) => {
  try {
    let res;
    if (id) {
      res = await getAdminAsync(id);
    } else {
      res = await getMyInfoAsync();
    }
    return res.data;
  } catch (err: any) {
    dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
  }
};
