import { AxiosPromise } from "axios";
import { TPaymentsStatistics } from "src/typings";

import http from "../https";

type getAllSunscriptionsParamsType = {
  startAt: string;
  finishAt: string;
  limit?: number;
  page?: number;
};

type TPaymentsStatisticsResponse = {
  data: TPaymentsStatistics[];
  pagination: {
    totalItems: number;
  };
  totalDate: TPaymentsStatistics;
};

export const getPaymentsStatistics = (
  params: getAllSunscriptionsParamsType
): AxiosPromise<TPaymentsStatisticsResponse> =>
  http.get("/api/payments/statistics", {}, { params });
