/*
================ FORMATS_DATE ================

  format_1 = dd.mm.yyyy
  format_2 = mm.dd.yyyy
  format_3 = yyyy.mm.dd

==============================================
*/
import { format } from "date-fns";

export const formattingDate = (date: string, formatDate: string) => {
  if (!date) {
    return "";
  }
  const middleDate = new Date(date);

  const dateWithOutTime = format(middleDate, "P").split("/");

  switch (formatDate) {
    case "dd.mm.yyyy":
      return `${dateWithOutTime[1]}.${dateWithOutTime[0]}.${dateWithOutTime[2]}`;
    case "mm.dd.yyyy":
      return `${dateWithOutTime[0]}.${dateWithOutTime[1]}.${dateWithOutTime[2]}`;
    case "yyyy.mm.dd":
      return `${dateWithOutTime[2]}.${dateWithOutTime[1]}.${dateWithOutTime[0]}`;
    default:
      return `---`;
  }
};
