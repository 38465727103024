export const ERRORS = {
  invalid_email: {
    backend_value: "email must be an email",
    alert: "Такого пользователя не существует!",
  },
  uniq_email_required: {
    backend_value: "A user with this email already exists",
    alert: "Такой пользователь уже существует",
  },
  invalid_pwd: {
    backend_value: "Invalid password",
    alert: "Пароль не подходит",
  },
  user_not_found: {
    backend_value: "User not found",
    alert: "Неверный email, такого пользователя не существует",
  },
};
