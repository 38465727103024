import http from "../https";

import { AxiosPromise } from "axios";
import { TPagination, TReferralsTotal, TRefferal } from "src/typings";

type GetReferralsListParamsType = {
  limit?: number;
  page?: number;
};

type TReferralsListResponse = {
  pagination: TPagination;
  data: TRefferal[];
  total: TReferralsTotal;
};

export const getReferralsList = (
  params: GetReferralsListParamsType
): AxiosPromise<TReferralsListResponse> => http.get("/api/referrals/list", {}, { params });
