export const SettingsIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.68449 5.06614C9.88784 4.15106 10.6995 3.5 11.6369 3.5H12.4282C13.3656 3.5 14.1772 4.15106 14.3806 5.06614L14.5835 5.97935C15.2446 6.23819 15.8568 6.59467 16.4022 7.03105L17.2964 6.74961C18.1906 6.46818 19.1602 6.84554 19.6289 7.65735L20.0246 8.34264C20.4933 9.15445 20.3352 10.1829 19.6444 10.8165L18.9542 11.4496C19.0058 11.7922 19.0325 12.143 19.0325 12.5C19.0325 12.857 19.0058 13.2078 18.9542 13.5504L19.6445 14.1835C20.3353 14.8171 20.4933 15.8455 20.0246 16.6574L19.6289 17.3426C19.1602 18.1545 18.1906 18.5318 17.2964 18.2504L16.4022 17.9689C15.8568 18.4053 15.2446 18.7618 14.5835 19.0206L14.3806 19.9339C14.1772 20.8489 13.3656 21.5 12.4282 21.5H11.6369C10.6995 21.5 9.88784 20.8489 9.68449 19.9339L9.48156 19.0206C8.82043 18.7618 8.20826 18.4053 7.66281 17.9689L6.76861 18.2504C5.87446 18.5318 4.90481 18.1545 4.43611 17.3426L4.04045 16.6573C3.57175 15.8455 3.72978 14.8171 4.42058 14.1835L5.1108 13.5504C5.05925 13.2077 5.03252 12.857 5.03252 12.5C5.03252 12.143 5.05925 11.7922 5.11081 11.4496L4.4206 10.8165C3.72979 10.1829 3.57177 9.15446 4.04047 8.34265L4.43612 7.65735C4.90482 6.84554 5.87447 6.46818 6.76863 6.74962L7.66282 7.03106C8.20827 6.59467 8.82043 6.23819 9.48156 5.97935L9.68449 5.06614Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.0325 12.5C13.0325 13.0523 12.5848 13.5 12.0325 13.5C11.4802 13.5 11.0325 13.0523 11.0325 12.5C11.0325 11.9477 11.4802 11.5 12.0325 11.5C12.5848 11.5 13.0325 11.9477 13.0325 12.5Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
