export const UsersIcon = () => {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4615 9.30743C13.5487 9.30743 12.6564 9.03674 11.8974 8.5296C11.1384 8.02245 10.5468 7.30163 10.1975 6.45828C9.84816 5.61493 9.75676 4.68693 9.93485 3.79163C10.1129 2.89633 10.5525 2.07395 11.198 1.42848C11.8435 0.783004 12.6658 0.343431 13.5611 0.165346C14.4564 -0.0127401 15.3844 0.0786599 16.2278 0.427987C17.0711 0.777315 17.792 1.36888 18.2991 2.12788C18.8062 2.88687 19.0769 3.77921 19.0769 4.69205C19.0769 5.91612 18.5907 7.09006 17.7251 7.95562C16.8596 8.82117 15.6856 9.30743 14.4615 9.30743ZM14.4615 1.92282C13.9138 1.92282 13.3784 2.08523 12.923 2.38952C12.4676 2.6938 12.1127 3.1263 11.9031 3.63231C11.6935 4.13832 11.6387 4.69512 11.7455 5.2323C11.8524 5.76947 12.1161 6.2629 12.5034 6.65019C12.8907 7.03747 13.3841 7.30122 13.9213 7.40807C14.4585 7.51492 15.0153 7.46008 15.5213 7.25048C16.0273 7.04088 16.4598 6.68595 16.7641 6.23055C17.0684 5.77515 17.2308 5.23975 17.2308 4.69205C17.2308 3.9576 16.939 3.25324 16.4197 2.7339C15.9004 2.21457 15.196 1.92282 14.4615 1.92282Z"
        fill="#1E202C"
      />
      <path
        d="M23.0769 17.923C22.8331 17.9199 22.6002 17.8216 22.4277 17.6491C22.2553 17.4767 22.157 17.2438 22.1539 17C22.1539 14.6 20.8492 13 14.4615 13C8.07385 13 6.76923 14.6 6.76923 17C6.76923 17.2448 6.67198 17.4796 6.49887 17.6527C6.32576 17.8258 6.09097 17.923 5.84616 17.923C5.60134 17.923 5.36655 17.8258 5.19344 17.6527C5.02033 17.4796 4.92308 17.2448 4.92308 17C4.92308 11.1538 11.6062 11.1538 14.4615 11.1538C17.3169 11.1538 24 11.1538 24 17C23.9968 17.2438 23.8985 17.4767 23.7261 17.6491C23.5537 17.8216 23.3208 17.9199 23.0769 17.923Z"
        fill="#1E202C"
      />
      <path
        d="M7.47076 10.3042H7.07691C6.09765 10.2095 5.1961 9.72973 4.5706 8.97035C3.94509 8.21097 3.64686 7.23421 3.74153 6.25495C3.83619 5.27569 4.31598 4.37414 5.07536 3.74864C5.83474 3.12313 6.8115 2.8249 7.79076 2.91957C7.91632 2.92496 8.03946 2.95594 8.15263 3.01061C8.26579 3.06527 8.3666 3.14247 8.44888 3.23747C8.53116 3.33248 8.59317 3.44328 8.63112 3.56309C8.66906 3.68291 8.68214 3.80921 8.66956 3.93425C8.65697 4.0593 8.61898 4.18046 8.55791 4.2903C8.49684 4.40015 8.41399 4.49637 8.31443 4.57306C8.21486 4.64976 8.10069 4.70531 7.97889 4.73633C7.8571 4.76734 7.73026 4.77315 7.60614 4.75341C7.36589 4.72946 7.12326 4.75416 6.89277 4.82606C6.66227 4.89796 6.44863 5.01557 6.2646 5.17187C6.07768 5.32265 5.92265 5.50912 5.80854 5.72043C5.69444 5.93174 5.62354 6.16365 5.59999 6.40264C5.57454 6.64463 5.5975 6.88927 5.66754 7.12229C5.73757 7.35532 5.85328 7.57207 6.00793 7.75994C6.16257 7.9478 6.35305 8.10301 6.56827 8.21652C6.7835 8.33003 7.01916 8.39958 7.26153 8.4211C7.66219 8.45538 8.0633 8.36049 8.40614 8.15034C8.61505 8.0214 8.86662 7.98073 9.10552 8.03728C9.34441 8.09383 9.55105 8.24297 9.67999 8.45187C9.80892 8.66078 9.84959 8.91236 9.79304 9.15125C9.73649 9.39014 9.58736 9.59678 9.37845 9.72572C8.80762 10.0898 8.14766 10.2899 7.47076 10.3042Z"
        fill="#1E202C"
      />
      <path
        d="M0.923077 16.9996C0.679253 16.9964 0.446312 16.8982 0.273888 16.7257C0.101464 16.5533 0.00318765 16.3204 0 16.0765C0 12.7535 0.886154 10.5381 5.23077 10.5381C5.47558 10.5381 5.71037 10.6353 5.88348 10.8084C6.05659 10.9816 6.15385 11.2163 6.15385 11.4612C6.15385 11.706 6.05659 11.9408 5.88348 12.1139C5.71037 12.287 5.47558 12.3842 5.23077 12.3842C2.33846 12.3842 1.84615 13.3073 1.84615 16.0765C1.84297 16.3204 1.74469 16.5533 1.57227 16.7257C1.39984 16.8982 1.1669 16.9964 0.923077 16.9996Z"
        fill="#1E202C"
      />
    </svg>
  );
};
