import { ProgressSpinner } from "primereact/progressspinner";

import styled from "./FullScreenSpin.module.scss";

export const FullScreenSpin = () => {
    return (
        <div className={styled["spin-wrapper"]}>
            <ProgressSpinner />
        </div>
    );
};
