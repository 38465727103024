export const StatisticIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0312 21.5L3.03125 21.5M15.5312 17.5V17.5C15.2551 17.5 15.0312 17.2761 15.0312 17L15.0312 10C15.0312 9.72386 15.2551 9.5 15.5312 9.5V9.5C15.8074 9.5 16.0312 9.72386 16.0312 10L16.0312 17C16.0312 17.2761 15.8074 17.5 15.5312 17.5ZM8.53125 17.5V17.5C8.25511 17.5 8.03125 17.2761 8.03125 17V4C8.03125 3.72386 8.25511 3.5 8.53125 3.5V3.5C8.80739 3.5 9.03125 3.72386 9.03125 4V17C9.03125 17.2761 8.80739 17.5 8.53125 17.5Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
