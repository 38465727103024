export const ProfitIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0312 12.5V14.5M12.0312 5.5H5.03125C3.92668 5.5 3.03125 6.39543 3.03125 7.5V14.5M3.03125 14.5V17.5C3.03125 18.6046 3.92668 19.5 5.03125 19.5H19.0313C20.1358 19.5 21.0312 18.6046 21.0312 17.5V14.5M3.03125 14.5H21.0312M23.0312 4.5L18.0312 9.5L16.0312 7.5"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
