import { store } from "src/store";
import { phrases } from "src/constants";
import { getMyInfoAsync } from "src/api";
import { addErrorMessage } from "src/slices";

const { dispatch } = store;

export const authLoader = async () => {
  try {
    const res = await getMyInfoAsync();
    return res.data;
  } catch (err: any) {
    dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
  }
};
