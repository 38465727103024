export const SubscriptionIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7754 16.9211C14.6189 17.2477 14.5312 17.6136 14.5312 18C14.5312 19.3807 15.6505 20.5 17.0312 20.5C18.412 20.5 19.5312 19.3807 19.5312 18C19.5312 16.6193 18.412 15.5 17.0312 15.5C16.0369 15.5 15.1781 16.0805 14.7754 16.9211ZM14.7754 16.9211L7.78711 13.5789M14.7754 8.07889C15.1781 8.91949 16.0369 9.5 17.0312 9.5C18.412 9.5 19.5312 8.38071 19.5312 7C19.5312 5.61929 18.412 4.5 17.0312 4.5C15.6505 4.5 14.5312 5.61929 14.5312 7C14.5312 7.38637 14.6189 7.75226 14.7754 8.07889ZM14.7754 8.07889L7.78711 11.4211M7.78711 11.4211C7.38436 10.5805 6.5256 10 5.53125 10C4.15054 10 3.03125 11.1193 3.03125 12.5C3.03125 13.8807 4.15054 15 5.53125 15C6.5256 15 7.38436 14.4195 7.78711 13.5789M7.78711 11.4211C7.9436 11.7477 8.03125 12.1136 8.03125 12.5C8.03125 12.8864 7.9436 13.2523 7.78711 13.5789"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
