import http from "../https";

type getMyCoursesProps = {
  page?: number;
  limit?: number;
  adminId?: number;
  status?: number;
  sort?: string;
};

export const getMyCourses = (params: getMyCoursesProps) =>
  http.get("/api/courses/my", {}, { params });
