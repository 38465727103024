import { lazy } from "react";
import { AdminPrivateRoute } from "./AdminPrivateRoute";
import { TAdmin, TCourseInfo, TLessonInfo } from "./typings";
import { Navigate, RouteObject, createBrowserRouter } from "react-router-dom";

import {
  authLoader,
  courseLoader,
  faqLoader,
  lessonLoader,
  profileLoader,
  reviewsLoader,
  settingsAdminInfoLoader,
  settingsEarningsInfoLoader,
} from "./loaders";

import App from "./App";
import PrivateRoute from "./PrivateRoute";

const Course = lazy(() => import("./features/Course"));
const PromoPage = lazy(() => import("./pages/PromoPage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const UsersPage = lazy(() => import("./pages/UsersPage"));
const ErrorPage = lazy(() => import("./pages/ErrorPages/ErrorPage"));
const LessonPage = lazy(() => import("./pages/LessonPage"));
const AdminsPage = lazy(() => import("./pages/AdminsPage"));
const ProfilePage = lazy(() => import("./pages/ProfilePage"));
const CoursesPage = lazy(() => import("./pages/CoursesPage"));
const ReviewsPage = lazy(() => import("./pages/ReviewsPage"));
const AdminInfoPage = lazy(() => import("./pages/AdminInfoPage"));
const DirectionsPage = lazy(() => import("./pages/DirectionsPage"));
const CourseErrorPage = lazy(() => import("./features/Course/ErrorPage"));
const ProfileFeedPage = lazy(() => import("./pages/ProfilePages/ProfileFeedPage"));
const LessonErrorPage = lazy(() => import("./features/Lesson"));
const ProfileDataPage = lazy(() => import("./pages/ProfilePages/ProfileDataPage"));
const ApplicationsPage = lazy(() => import("./pages/Applications"));
const SunscriptionPage = lazy(() => import("./pages/SunscriptionPage"));
const RefferalListPage = lazy(() => import("./pages/RefferalListPage"));
const PromoEarinigsPage = lazy(() => import("./pages/PromoEarinigsPage"));
const CancelReasonsPage = lazy(() => import("./pages/CancelReasonsPage"));
const AdminProfileError = lazy(() => import("./pages/ErrorPages/AdminProfilePageError"));
const RefferalSystemPage = lazy(() => import("./pages/RefferalSystemPage"));
const ProfileCoursesPage = lazy(() => import("./pages/ProfilePages/ProfileCoursesPage"));
const OnboardingStatPage = lazy(() => import("./pages/OnboardingStatPage"));
const ProfileServicesPage = lazy(() => import("./pages/ProfilePages/ProfileServicesPage"));
const ProfileSettingsPage = lazy(() => import("./pages/ProfilePages/ProfileSettingsPage"));
const ProfileQuestionsPage = lazy(() => import("./pages/ProfilePages/ProfileQuestionsPage"));
const ProfileBroadcastsPage = lazy(() => import("./pages/ProfilePages/ProfileBroadcastsPage"));
const PaymentsStatisticsPage = lazy(() => import("./pages/PaymentsStatisticsPage"));
const ProfileEarningsAdditionalPage = lazy(
  () => import("./pages/ProfilePages/ProfileEarningsAdditionalPage")
);

const profileRoutes = [
  {
    path: "",
    element: <ProfileDataPage />,
    children: [
      {
        path: "",
        element: <Navigate to="courses" />,
      },
      {
        path: "courses",
        element: <ProfileCoursesPage />,
      },
      {
        path: "questions",
        element: <ProfileQuestionsPage />,
      },
      {
        path: "feed",
        element: <ProfileFeedPage />,
      },
      {
        path: "services",
        element: <ProfileServicesPage />,
      },
      {
        path: "broadcasts",
        element: <ProfileBroadcastsPage />,
      },
    ],
  },
  {
    path: "settings",
    element: <ProfileSettingsPage />,
    handle: {
      crumb: () => ({ label: "Настройки", to: "settings" }),
    },
  },
  {
    path: "earnings",
    element: <ProfileEarningsAdditionalPage />,
    handle: {
      crumb: () => ({ label: "Информация про заработок", to: "earnings" }),
    },
  },
];

const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      { path: "login", element: <LoginPage /> },
      {
        path: "",
        element: <PrivateRoute />,
        loader: authLoader,
        errorElement: <ErrorPage />,
        children: [
          { path: "", element: <Navigate to={"/profile/courses"} /> },
          {
            path: "",
            element: <AdminPrivateRoute />,
            children: [
              { path: "", element: <Navigate to={"/users"} /> },
              {
                path: "users",
                element: <UsersPage />,
                handle: {
                  crumb: () => ({ label: "Пользователи", to: "users" }),
                },
              },
              {
                path: "applications",
                element: <ApplicationsPage />,
                handle: {
                  crumb: () => ({ label: "Услуги", to: "applications" }),
                },
              },
              {
                path: "subscriptions",
                element: <SunscriptionPage />,
                handle: {
                  crumb: () => ({ label: "Подписки", to: "subscriptions" }),
                },
              },
              {
                path: "payments-statistics",
                element: <PaymentsStatisticsPage />,
                handle: {
                  crumb: () => ({ label: "Статистика", to: "payments-statistics" }),
                },
              },
              {
                path: "cancel-reasons",
                element: <CancelReasonsPage />,
                handle: {
                  crumb: () => ({ label: "Причины отмены", to: "cancel-reasons" }),
                },
              },
              {
                path: "admins",
                handle: {
                  crumb: () => ({ label: "Администраторы", to: "admins" }),
                },
                children: [
                  {
                    path: "",
                    element: <AdminsPage />,
                  },
                  {
                    path: ":id",
                    id: "admin-profile",
                    element: <ProfilePage />,
                    errorElement: <AdminProfileError />,
                    loader: ({ params }) => profileLoader(params.id),
                    handle: {
                      crumb: (data: TAdmin) => {
                        if (data?.id) {
                          const { firstName, lastName, id } = data;
                          return { label: `${lastName} ${firstName}`, to: `${id}` };
                        } else return undefined;
                      },
                    },
                    children: profileRoutes,
                  },
                ],
              },
              {
                path: "courses",
                handle: {
                  crumb: () => ({ label: "Курсы", to: "courses" }),
                },
                children: [
                  {
                    path: "",
                    element: <CoursesPage />,
                  },
                ],
              },
              {
                path: "directions",
                element: <DirectionsPage />,
                handle: {
                  crumb: () => ({ label: "Направления", to: "directions" }),
                },
              },
              {
                path: "referral-system",
                element: <RefferalSystemPage />,
                handle: {
                  crumb: () => ({ label: "Прибыль", to: "referral-system" }),
                },
              },
              {
                path: "settings-reviews",
                loader: reviewsLoader,
                element: <ReviewsPage />,
                errorElement: <ErrorPage />,
                handle: {
                  crumb: () => ({ label: "Настройки - Отзывы", to: "settings-reviews" }),
                },
              },
            ],
          },
          {
            path: "settings-promo",
            element: <PromoPage />,
            loader: faqLoader,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => ({ label: "Настройки - Промо", to: "settings-promo" }),
            },
          },
          {
            path: "earnings",
            element: <ProfilePage />,
            errorElement: <ErrorPage />,
            loader: () => profileLoader(),
            children: [
              {
                path: "",
                element: <ProfileEarningsAdditionalPage />,
                handle: {
                  crumb: () => ({ label: "Профиль", to: "../profile" }),
                },
              },
            ],
          },
          {
            path: "profile",
            id: "profile",
            element: <ProfilePage />,
            errorElement: <ErrorPage />,
            loader: () => profileLoader(),
            handle: {
              crumb: () => ({ label: "Профиль", to: "profile" }),
            },
            children: profileRoutes,
          },
          {
            path: "courses",
            handle: {
              crumb: () => ({ label: "Курсы", to: "courses" }),
            },
            children: [
              {
                path: ":id",
                id: "course",
                loader: ({ params }) => courseLoader(params.id),
                shouldRevalidate: ({ nextParams }) => {
                  return !nextParams.lessonId;
                },

                handle: {
                  crumb: (data: TCourseInfo) => ({ label: data?.name, to: `${data?.id}` }),
                },
                errorElement: <CourseErrorPage />,
                children: [
                  {
                    path: "",
                    element: <Course />,
                  },
                  {
                    path: ":lessonId",
                    element: <LessonPage />,
                    errorElement: <LessonErrorPage />,
                    loader: ({ params }) => lessonLoader(params.lessonId),
                    handle: {
                      crumb: (data: TLessonInfo) => ({ label: data?.name, to: `${data?.id}` }),
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "settings-admin-info",
            element: <AdminInfoPage />,
            loader: settingsAdminInfoLoader,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => ({
                label: "Настройки - Информация для администраторов",
                to: "settings-admin-info",
              }),
            },
          },
          {
            path: "settings-earnings",
            element: <PromoEarinigsPage />,
            loader: settingsEarningsInfoLoader,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => ({
                label: "Настройки - Заработок",
                to: "settings-earnings",
              }),
            },
          },
          {
            path: "refferal-list",
            element: <RefferalListPage />,
            handle: {
              crumb: () => ({ label: "Рефералы", to: "refferal-list" }),
            },
          },
          {
            path: "onboarding-statistics",
            element: <OnboardingStatPage />,
            handle: {
              crumb: () => ({ label: "Статистика по онбордингу", to: "onboarding-statistics" }),
            },
          },
        ],
      },
    ],
  },
];

export default createBrowserRouter(routes);
