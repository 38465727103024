import { API_URL } from "../constants/config";

import axios from "axios";

const http = axios.create({
  baseURL: API_URL,
});

http.interceptors.request.use(
  (config) => {
    const key = localStorage.getItem("token");
    if (key) {
      config.headers = {
        Authorization: `Bearer ${key}`,
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

const get = (url: string, headers = {}, params = {}) => {
  return http.get(url, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const post = (url: string, data: any, headers = {}, params = {}) => {
  return http.post(url, data, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const put = (url: string, data: any, headers = {}) => {
  return http.put(url, data, {
    headers: {
      ...headers,
    },
  });
};

const remove = (url: string, data: any, headers = {}) => {
  return http.delete(url, {
    headers: {
      ...headers,
    },
    data,
  });
};
const patch = (url: string, data: any, headers = {}) => {
  return http.patch(url, data, {
    headers: {
      ...headers,
    },
  });
};

const module = {
  http,
  get,
  post,
  put,
  remove,
  patch,
};

export default module;
