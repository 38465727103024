export const APPLICATION_STATUSES = [
  {
    label: "Новая",
    value: "new",
  },
  {
    label: "В работе",
    value: "inProgress",
  },
  {
    label: "Отказ",
    value: "rejection",
  },
  {
    label: "Оплачено",
    value: "paid",
  },
  {
    label: "Все",
    value: "all",
  },
];

export const AVAILABLE_APPLICATION_STATUSES = [
  {
    label: "Новая",
    value: "new",
  },
  {
    label: "В работе",
    value: "inProgress",
  },
  {
    label: "Отказ",
    value: "rejection",
  },
  {
    label: "Оплачено",
    value: "paid",
  },
];

export const APPLICATION_STATUSES_LABELS = {
  new: "Новая",
  inProgress: "В работе",
  rejection: "Отказ",
  paid: "Оплачено",
} as const;
