import http from "../https";

type getAllSunscriptionsParamsType = {
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
};

export const getSubscriptionsListAsync = (params: getAllSunscriptionsParamsType) =>
  http.get("/api/payments", {}, { params });
