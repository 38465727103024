export const SupportIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.03125 13.5V17.5C4.03125 18.6046 4.92668 19.5 6.03125 19.5V19.5C7.13582 19.5 8.03125 18.6046 8.03125 17.5V15.5C8.03125 14.3954 7.13582 13.5 6.03125 13.5H4.03125ZM4.03125 13.5V10.5C4.03125 6.08172 7.61297 2.5 12.0312 2.5V2.5C16.4495 2.5 20.0312 6.08172 20.0312 10.5V13.5M20.0312 13.5V17.5C20.0312 18.6046 19.1358 19.5 18.0312 19.5V19.5C16.9267 19.5 16.0312 18.6046 16.0312 17.5V15.5C16.0312 14.3954 16.9267 13.5 18.0312 13.5H20.0312Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
