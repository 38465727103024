import http from "../https";

type TCreateNewsData = {
    header: string;
    text: string;
    imageUrl?: string;
};

export const createNews = (data: TCreateNewsData, adminId?: string) =>
    http.post("/api/news", data, {}, { params: { adminId } });
