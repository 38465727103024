import { useLocation, useResolvedPath as routerUseResolvedPath } from "react-router-dom";
import { UNSAFE_NavigationContext } from "react-router";
import { useContext } from "react";

const isActiveRoute = (pathname, to, navigator, end = false, ...rest) => {
  let path = routerUseResolvedPath(to);

  let toPathname = navigator.encodeLocation
    ? navigator.encodeLocation(path).pathname
    : path.pathname;

  const endSlashPosition =
    toPathname !== "/" && toPathname.endsWith("/") ? toPathname.length - 1 : toPathname.length;
  return (
    pathname === toPathname ||
    (!end && pathname.startsWith(toPathname) && pathname.charAt(endSlashPosition) === "/")
  );
};

export const useGetActiveLink = (links: string[]) => {
  let { pathname } = useLocation();
  let { navigator } = useContext(UNSAFE_NavigationContext);

  let activeLink = "";
  for (let link of links) {
    if (isActiveRoute(pathname, link, navigator)) {
      activeLink = link;
    }
  }
  return activeLink;
};
