import { store } from "src/store";
import { phrases } from "src/constants";
import { getFaq, getTags } from "src/api";
import { addErrorMessage } from "src/slices";
import { TFaqLoaderResult } from "src/typings";

const { dispatch } = store;

export const settingsEarningsInfoLoader = async (): Promise<TFaqLoaderResult | undefined> => {
  try {
    const [faq, tags] = await Promise.all([getFaq("income"), getTags("income")]);
    return { faq: faq.data, tags: tags.data };
  } catch (err: any) {
    dispatch(addErrorMessage(err?.response?.data?.message || phrases.smthWentWrongText));
  }
};
