import { FC } from "react";
import { Tag } from "./Tag";
import { useForm } from "react-hook-form";
import { phrases } from "src/constants";
import { InputText } from "primereact/inputtext";
import { createTag } from "src/api/tags";
import { classNames } from "primereact/utils";
import { addErrorMessage } from "src/slices";
import { TFaqLoaderResult } from "src/typings";
import { useAppDispatch, useGetRoles } from "src/utils";
import { useLoaderData, useRevalidator } from "react-router";

import styled from "./Tags.module.scss";

type TagsProps = {
  type: string;
};

export const Tags: FC<TagsProps> = ({ type }: TagsProps) => {
  const { tags } = useLoaderData() as TFaqLoaderResult;

  const revalidator = useRevalidator();

  const dispatch = useAppDispatch();

  const { isAdmin } = useGetRoles();

  const { register, handleSubmit, setValue } = useForm<{ header: string }>({
    defaultValues: {
      header: "",
    },
  });

  const onSubmit = async ({ header }: { header: string }) => {
    const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    try {
      await createTag(type, {
        header,
        color,
      });
      revalidator.revalidate();
    } catch (err: any) {
      dispatch(addErrorMessage(err.response.data.message || phrases.smthWentWrongText));
    }
    setValue("header", "");
  };

  return (
    <div className={styled["container"]}>
      <p className={styled["title"]}>Теги</p>
      {isAdmin && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <span
            className={classNames("p-input-icon-left p-main-input", styled["search-input-wrapper"])}
          >
            <i className="pi pi-search" />
            <InputText
              {...register("header", { required: true })}
              required
              maxLength={700}
              type="text"
              placeholder="Добавить категорию"
              className={classNames(styled["search-input"], "p-inputtext-light-bg")}
            />
          </span>
        </form>
      )}
      <div className={styled["list"]}>
        {tags.map((tag) => (
          <Tag key={tag.id} name={tag.header.toLocaleUpperCase()} color={tag.color} />
        ))}
      </div>
    </div>
  );
};
