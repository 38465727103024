export const BookIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5841_1717)">
        <path
          d="M21.5986 0.00143561H4.80105C3.91953 -0.0244346 3.0637 0.300215 2.42112 0.904239C1.77854 1.50826 1.40162 2.34239 1.37297 3.22383V20.2271C1.40875 21.2613 1.85327 22.2391 2.60903 22.9461C3.36479 23.653 4.37008 24.0313 5.40439 23.998H21.5986C21.8703 23.9944 22.1298 23.8849 22.3219 23.6928C22.514 23.5007 22.6235 23.2412 22.6271 22.9696V1.02986C22.6235 0.758208 22.514 0.498683 22.3219 0.306581C22.1298 0.114479 21.8703 0.00498705 21.5986 0.00143561ZM20.5702 21.9411H5.40439C4.91539 21.9751 4.43289 21.8137 4.06273 21.4923C3.69256 21.171 3.46495 20.716 3.42982 20.2271C3.46495 19.7382 3.69256 19.2832 4.06273 18.9618C4.43289 18.6405 4.91539 18.4791 5.40439 18.5131H20.5702V21.9411ZM20.5702 16.4562H5.40439C4.71405 16.457 4.03501 16.6314 3.42982 16.9636V3.22383C3.46122 2.88909 3.62218 2.57987 3.87835 2.36213C4.13452 2.14439 4.46562 2.03535 4.80105 2.05828H20.5702V16.4562Z"
          fill="#293241"
        />
        <path
          d="M7.54351 7.54317H16.4565C16.7293 7.54317 16.9909 7.43482 17.1837 7.24196C17.3766 7.04909 17.4849 6.78751 17.4849 6.51475C17.4849 6.242 17.3766 5.98041 17.1837 5.78755C16.9909 5.59468 16.7293 5.48633 16.4565 5.48633H7.54351C7.27076 5.48633 7.00918 5.59468 6.81631 5.78755C6.62344 5.98041 6.51509 6.242 6.51509 6.51475C6.51509 6.78751 6.62344 7.04909 6.81631 7.24196C7.00918 7.43482 7.27076 7.54317 7.54351 7.54317Z"
          fill="#212121"
        />
        <path
          d="M7.54351 12.3425H16.4565C16.7293 12.3425 16.9909 12.2341 17.1837 12.0413C17.3766 11.8484 17.4849 11.5868 17.4849 11.3141C17.4849 11.0413 17.3766 10.7797 17.1837 10.5869C16.9909 10.394 16.7293 10.2856 16.4565 10.2856H7.54351C7.27076 10.2856 7.00918 10.394 6.81631 10.5869C6.62344 10.7797 6.51509 11.0413 6.51509 11.3141C6.51509 11.5868 6.62344 11.8484 6.81631 12.0413C7.00918 12.2341 7.27076 12.3425 7.54351 12.3425Z"
          fill="#212121"
        />
      </g>
      <defs>
        <clipPath id="clip0_5841_1717">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
