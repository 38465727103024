import http from "../https";

type getQuestionsParamsType = {
  page?: number;
  limit?: number;
  isRead?: boolean;
  adminId?: number;
};

export const getQuestions = (params: getQuestionsParamsType) =>
  http.get("/api/questions", {}, { params });
