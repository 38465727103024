import http from "../https";

import { downloadCSV } from "src/shared/helpers";

type getUsersCSVParams = {
  search?: string;
  status?: string;
};

export const getUsersCSV = async (params: getUsersCSVParams) => {
  const response = await http.get(
    "/api/students/csv",
    {
      responseType: "text/csv",
    },
    { params }
  );

  return downloadCSV(response.data, "users.csv");
};
