import http from "../https";

type getAllApplicationsParamsType = {
  page?: number;
  limit?: number;
  search?: string;
  status?: number;
};

export const getApplications = (params: getAllApplicationsParamsType) =>
  http.get("/api/applications", {}, { params });
