import http from "../https";

type getAllAdminsParamsType = {
    page?: number;
    limit?: number;
    search?: string;
    directionId?: number;
    speakerId?: string;
    status?: number;
};

export const getCourses = (params: getAllAdminsParamsType) =>
    http.get("/api/courses", {}, { params });
