import { Link } from "react-router-dom";
import { Button } from "primereact/button";

import styles from "./error.module.scss";

export const NoAccessPage = () => (
  <div className={styles["error-page"]}>
    <h2>Нет доступа</h2>
    <Link to={`/profile`}>
      <Button>Вернуться к профилю</Button>
    </Link>
  </div>
);

export default NoAccessPage;
