export const DirectionsIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.03125 16.5H3.03125C2.47897 16.5 2.03125 16.0523 2.03125 15.5V3.5C2.03125 2.94772 2.47897 2.5 3.03125 2.5H15.0312C15.5835 2.5 16.0312 2.94772 16.0312 3.5V8.5M8.03125 16.5V21.5C8.03125 22.0523 8.47897 22.5 9.03125 22.5H21.0312C21.5835 22.5 22.0312 22.0523 22.0312 21.5V9.5C22.0312 8.94772 21.5835 8.5 21.0312 8.5H16.0312M8.03125 16.5V14.5M8.03125 16.5H10.0312M16.0312 8.5H14.0312M16.0312 8.5V10.5M16.0312 14.5V15.5C16.0312 16.0523 15.5835 16.5 15.0312 16.5H14.0312M8.03125 10.5V9.5C8.03125 8.94772 8.47897 8.5 9.03125 8.5H10.0312"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
