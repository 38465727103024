export const OnboaringStatisticsIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.03125 17.5L9.03125 11.5L13.0312 15.5L21.0312 7.5M21.0312 7.5V12.5M21.0312 7.5H16.0312"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
