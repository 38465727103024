export const ROLES = [
  {
    label: "Все роли",
    value: "all",
  },
  {
    label: "Администратор",
    value: "admin",
  },
  {
    label: "Пользователь",
    value: "curator",
  },
];

export const STATUSES = [
  {
    label: "Все статусы подписки",
    value: "all",
  },
  {
    label: "Подписка активна",
    value: "active",
  },
  {
    label: "Подписка не активна",
    value: "notActive",
  },
  {
    label: "Подписка закончилась",
    value: "finished",
  },
];

export const FILTER_ADMIN_ROLES = [
  {
    label: "Все роли",
    value: "all",
  },
  {
    label: "Администратор",
    value: "admin",
  },
  {
    label: "Куратор",
    value: "curator",
  },
  {
    label: "Спикер",
    value: "speaker",
  },
];
